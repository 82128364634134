
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,600&display=swap');

:root {
  /* dark shades of primary color*/
  --clr-primary-1: hsl(205, 86%, 17%);
  --clr-primary-2: hsl(205, 77%, 27%);
  --clr-primary-3: hsl(205, 72%, 37%);
  --clr-primary-4: hsl(205, 63%, 48%);
  /* primary/main color */
  --clr-primary-5: hsl(205, 78%, 60%);
  /* lighter shades of primary color */
  --clr-primary-6: hsl(205, 89%, 70%);
  --clr-primary-7: hsl(205, 90%, 76%);
  --clr-primary-8: hsl(205, 86%, 81%);
  --clr-primary-9: hsl(205, 90%, 88%);
  --clr-primary-10: hsl(205, 100%, 96%);
  /* darkest grey - used for headings */
  --clr-grey-1: hsl(209, 61%, 16%);
  --clr-grey-2: hsl(211, 39%, 23%);
  --clr-grey-3: hsl(209, 34%, 30%);
  --clr-grey-4: hsl(209, 28%, 39%);
  /* grey used for paragraphs */
  --clr-grey-5: hsl(210, 22%, 49%);
  --clr-grey-6: hsl(209, 23%, 60%);
  --clr-grey-7: hsl(211, 27%, 70%);
  --clr-grey-8: hsl(210, 31%, 80%);
  --clr-grey-9: hsl(212, 33%, 89%);
  --clr-grey-10: hsl(210, 36%, 96%);
  --clr-white: #fff;

  --clr-black-1: hsl(0, 0%, 0%);

  --clr-primary-red: hsl(0, 85%, 52%);
}

body{
  /* font-family: 'Nunito Sans', 'sans-serif'; */
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

.custom-btn{
  border-color: var(--clr-primary-3);
  background-color: var(--clr-primary-3)
}

.custom-btn:hover{
  border-color: var(--clr-grey-1);
  background-color: rgba(0, 0, 0, 0);
  color: var(--clr-primary-red);
}
.link{
  color: var(--clr-black-1);
  list-style: none;
  text-decoration: none;
  font-weight: 600;
}

.link:hover{
  color: var(--clr-primary-3);
}
.active{
  border-bottom-color: var(--clr-primary-3);
  border-bottom: 3px solid var(--clr-primary-3);
}

.bg-color{
  background-color: #4f667e;
}

.custom-color-btn{
  background-color: #ec6e59;
  border: 1px solid #ec6e59;
}

.custom-color{
  color: var(--clr-primary-1);
  font-weight: 300;
}

.custom-font{
  font-style: italic; 
  font-weight: 600;
  color: var(--clr-primary-3);

}

.custom-font{
  max-width: 700px;
  
}

.project-img{
  max-width: 400px;
  object-fit: cover;
  max-height: 100%;        
  transition: all 0.4s ease;
  opacity: 0.9;
}
      
 
.project-img:hover{
  opacity: 1;
  transition: all 0.4s ease;
}
@media (min-width: 768px){
  .form-class{
    width: 500px;
  }
}

/* --------------------------
    Navabar
-------------------------- */

 .logo-icons {
  position: relative !important;
  display: inline-block !important;
  color: #f0b536fd;
  width: 40px !important;
  height: 45px !important;
}
.btn-contact{
  border-radius: 5px;
  font-size: larger;
  color: #f9f8f8;
  text-align:center;
  border-color: var(--clr-primary-3);
  background-color: #4f667e;
}

.btn-contact:hover{
  background-color: whitesmoke;
  color: var(--clr-primary-red);
}


/* --------------------------
     About Techonologies
-------------------------- */

 .about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: rgb(230, 218, 218) !important;
}
 
.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(0, 0, 0, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(243, 6, 6, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  /* border: none !important; */
  color: rgb(84, 50, 50)portant;
  background-color: transparent !important;

}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

.project-heading{
  font-family: cursive;
}
b{
  color: rgb(249, 86, 86);
}

/* --------------------------
     Home Sections
-------------------------- */
.intro{
  color: #f37a6a;
}
.Typewriter__wrapper {
  font-size: 1.2em !important;
 
}
.Typewriter__cursor {
  font-size: 1.4em !important;
  padding-left: 5px;
  color: #ef0000 !important;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: rgb(15, 14, 14) !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 55px !important;
  height: 55px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(216, 216, 216, 0.972) !important;
  border-radius: 26% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #68187a;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #c401f0;
}

.home-social-icons:hover {
  color: #87209e;
  box-shadow: 0 0 5px #87209e;
  text-shadow: 0 0 2px #9e2020;
}

.social-icons {
  display: inline-block !important;
  padding-right: 8px;
  padding-left: 8px;
}

.icon-colour {
  color: #792e85 !important;
}
@media (max-width: 767px) {
  .social-icons {
    margin: 10px !important;
  }
}  

.footer{
  margin-left: 30rem;
}
@media (max-width: 767px) {
  .footer {
    margin: 10px !important;
  }
}  

#progress{
 background-color: rgb(255, 255, 255);
  position: fixed;
  bottom:40px;
  right: 25px;
  height: 60px;
  width: 60px;
  display: grid;
  place-items: center;
  border-radius: 55%;
  box-shadow: 0 0 12px rgb(0, 0, 0,0.2);
  cursor: pointer;
}

#progress-value{
  display: block;
  height: calc(100% - 15px);
  width: calc(100% - 15px);
  background-color: #fff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  font-size:25px;
  color: #001a2e;
}

@media (max-width: 767px) {
  #progress{
    margin: 10px !important;
  }
} 
